import React from 'react';
import Head from "../components/Head";
import Layout from "../components/Layout";

const NotFoundPage = () => {
    return (
        <Layout>
          <Head pageTitle="Page not found" title="Page not found" path="/" description="Page not found" imageUrl="" type="website" datePublished="01/04/2022" />
          <main class="flex-shrink-1">
            <div className="container">
              <section>
                <h1>Page not found</h1>
              </section>
            </div>
          </main>
        </Layout>
    );
}

export default NotFoundPage;
