exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cloud-series-jsx": () => import("./../../../src/pages/cloud-series.jsx" /* webpackChunkName: "component---src-pages-cloud-series-jsx" */),
  "component---src-pages-cloud-transformation-jsx": () => import("./../../../src/pages/cloud-transformation.jsx" /* webpackChunkName: "component---src-pages-cloud-transformation-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-of-use-jsx": () => import("./../../../src/pages/terms-of-use.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-jsx" */),
  "component---src-templates-all-posts-jsx": () => import("./../../../src/templates/all-posts.jsx" /* webpackChunkName: "component---src-templates-all-posts-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */)
}

