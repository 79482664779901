import React, {useEffect} from 'react';
import { Link } from 'gatsby';
import Head from '../components/Head';
import Layout from "../components/Layout";
import Archivelist from '../components/ArchiveList';
import Ebookwidget from '../components/EbookWidget';
import '../assets/css/blog.css';

const AllPosts = ({ pageContext: { allPosts } }) => {

  //console.log("allPosts from AllPosts:" + JSON.stringify(allPosts));

  const leadStory = allPosts[0];
  let followingTwoStories = [];
  let fireHosePosts = [];
  if (allPosts.length >= 3) {
    followingTwoStories.push(allPosts[1]);
    followingTwoStories.push(allPosts[2])
  }
  if (allPosts.length >= 4) {
    const remainingStories = allPosts.length - 3;
    fireHosePosts = allPosts.slice(-remainingStories);
  }

  //renderPostLink
  const renderPostLink = (linkParams) => {
    let link = <></>;
    switch (linkParams.post.postType) {
      case "curated":
        const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
        const linkUrl = (linkParams.post.content.match(linkRx)[2]);
        link = <Link className={linkParams.class} href={linkUrl} target="_blank" rel="noopener noreferrer">Go to article...</Link>
        break;
      default:
        link = <Link className={linkParams.class} to={`/knowledge-exchange/${linkParams.post.id}`}>Continue reading...</Link>
      }
    return link;
  }

  const formatDate = (post) => {
    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    let postDate = new Date(post.date);
    return(postDate.toLocaleDateString("en-US", dateOptions));
  }

  // the two 'following stories' in cards
  const renderFollowingTwoStories = (stories) => {
    return (
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 g-3 mb-4">
        {stories.map((story, index) => (
          <div className="col d-flex" key={story.id}>
            <div className="card shadow-sm" data-aos="flip-right" data-aos-duration="1000" data-aos-once="true">
              <div className="d-none d-sm-block"
              style={{height:"150px", backgroundImage:`url(${story.image})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}
              >&nbsp;</div>
              <div className="card-body flex-column d-flex">
              <h4 className="card-title">{story.title}</h4>
              <div className="mb-1 text-muted">{story.author}, {formatDate(story)}</div>
              { <span className="card-text small" dangerouslySetInnerHTML={{ __html: story.briefDescription }} /> }
              <div style={{marginTop: "auto"}}>
                {renderPostLink(
                  {
                    post: story,
                    class: "align-items-end stretched-link"
                  }
                )}
              </div>
            </div>
          </div>
        </div>
        ))}

      </div>
    )

  }


  return (
    <Layout>
      <Head pageTitle="Knowledge Exchange" title="Knowledge Exchange" path="/knowledge-exchange" description="Where we share trends, content, and commentary regarding our rapidly evolving digital universe." imageUrl="https://cms-imagebucket.s3.amazonaws.com/full/KnowledgeExchange.jpg" type="website" datePublished={(new Date().getMonth() + 1) + '/' + new Date().getDate() + '/' +  new Date().getFullYear()} />
      <style dangerouslySetInnerHTML={{__html: `
          body {
              padding-top: 70px;
          }
      `}} />
      <main className="flex-shrink-1">
        <div className="container">
          <div className="text-center">
            <h2 className="section-heading text-uppercase">Knowledge Exchange</h2>
            <h3 className="section-subheading text-muted">Where we share trends, content, and commentary regarding our rapidly evolving digital universe. Come along with us on our journey into the future. Imagine: what if? What next?</h3>
          </div>

          {/* Lead Story */}  
          <div className="p-4 p-md-5 mb-4 text-white rounded lead-story" 
            style={{background:`linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), url(${leadStory.image})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}
            data-aos="zoom-in-down" data-aos-once="true">
            <div className="col-md-6 px-0">
              <h1 className="display-4 fst-italic">{leadStory.title}</h1>
              { <span className="lead my-3" dangerouslySetInnerHTML={{ __html: leadStory.briefDescription }} /> }
              {renderPostLink(
                {
                  post: leadStory,
                  class: "text-white fw-bold"
                }
              )}
            </div>
          </div>

          {/* Following 2 Stories */}
          {renderFollowingTwoStories(followingTwoStories)}

          {/* From the Firehose */}
          <h3 className="pb-4 mb-4 fst-italic border-bottom">From the Firehose</h3>
          <div className="row g-5">
            <div className="col-md-8">
    
              {fireHosePosts.map(post => (
                <article className="blog-post border-bottom" key={post.id}><h2>{post.title}</h2>
                  <p className="blog-post-meta">{post.author}, {formatDate(post)}</p>
                  { <div dangerouslySetInnerHTML={{ __html: post.briefDescription }} /> }
                  <p>
                  {renderPostLink(
                    {
                      post: post,
                      class: "align-items-start"
                    }
                  )}
                  </p>
                </article>
              ))}
              
            </div>
              
            <div className="col-md-4">
                    <div className="position-sticky" style={{top:"5rem"}}>
                      <div className="p-4 mb-3 bg-light rounded">
                        <h4 className="fst-italic">About</h4>
                        <p className="lh-sm">The <em>Knowledge Exchange</em> is a space where we share research, trends, content, and commentary regarding our rapidly evolving digital universe.</p>
                        <p className="mb-1 lh-sm">Stay up-to-date and never miss a post.</p>
                        <p className="text-center"><a href="#" data-bs-target="#modal_subscribe" data-bs-toggle="modal" className="btn btn-primary-outline btn-sm rounded-3 border-1" title="Subscribe to Knowledge Exchange"><i class="bi bi-newspaper"></i> Subscribe to Knowledge Exchange</a></p>
                      </div>

                      <Ebookwidget padding="p-4" />
              
                      <div className="p-4">
                        <div id="placeholder_postList">
                          <Archivelist props={
                            {
                              allPosts: allPosts,
                              postId: null
                            }
                          }/>
                        </div>
                        <h4 className="fst-italic mt-4">Elsewhere</h4>
                        <ol className="list-unstyled">
                          <li><a href="https://twitter.com/intraprise" target="_blank" title="visit our Twitter"><i className="bi bi-twitter"></i> Twitter</a></li>
                          <li><a href="https://www.linkedin.com/company/intraprise-solutions" target="_blank" title="visit our LinkedIn"><i className="bi bi-linkedin"></i> LinkedIn</a></li>
                        </ol>
                      </div>
              
                    </div>
            </div>
          </div>



        </div>
      </main>
    </Layout>

  );
}

export default AllPosts;
