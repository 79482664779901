import React from 'react';
import { Link } from 'gatsby';
import SocGraphic from '../assets/img/SOC2.png';

const Footer = () => {
    return (
        <footer className="footer mt-auto bg-dark py-4">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4 text-lg-start text-light">Copyright &copy; Intraprise {new Date().getFullYear()}<br/>Phone: 267-274-2290</div>
                    <div className="col-lg-4 my-3 my-lg-0">
                        <a className="btn btn-primary btn-social mx-2" href="https://twitter.com/intraprise" target="_blank" rel="noreferrer" title="visit our Twitter"><i className="bi bi-twitter"></i></a>
                        <a className="btn btn-primary btn-social mx-2" href="https://www.linkedin.com/company/intraprise-solutions" target="_blank" rel="noreferrer" title="visit our LinkedIn"><i className="bi bi-linkedin"></i></a>
                    </div>
                    <div className="col-lg-4">
                        <div className="float-lg-end text-center d-sm-block">
                            <img src={SocGraphic} height="50" width="50" alt="AICPA Soc - SOC 2 compliant" title="AICPA Soc - SOC 2 compliant" />
                        </div>
                        <div className="text-lg-end text-sm-center">
                            <Link className="link-light me-3" href="/privacy-policy">Privacy Policy</Link>
                            <Link className="link-light me-3" to="/terms-of-use">Terms of Use</Link>
                            <br/><a className="link-light me-3" href="#" data-bs-toggle="modal" data-bs-target="#modal_optout">Do Not Sell or Share My Personal Information</a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
