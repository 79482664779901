import React from 'react';
import { Link } from 'gatsby';
import IconEbook from "../assets/img/icon-ebook.svg";

const Ebookwidget = (props) => {
    return (
        <div className='bg-dark position-relative'>
            <div className={props.padding}>
                <Link to={"/cloud-series"} className="stretched-link"><img height="46" width="65" src={IconEbook} alt="eBook" className="float-start me-1" /></Link>
                <h5 className="text-light">eBook: Cloud Computing</h5>
                <p className="text-light small">Download Now! Plan for now, prepare for tomorrow with the latest insights on Cloud Computing in our rapidly changing digital world.</p>
            </div>
        </div>
    );
}

export default Ebookwidget;
