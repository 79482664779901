import React, { useEffect, useRef, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";
import Head from "../components/Head";
import IconOngoingBigData from "../assets/img/icon-ongoing-big-data.png";
import IconStrategicPlanning from "../assets/img/icon-cusom-strategic-planning.png";
import IconInformedHighPerf from "../assets/img/icon-informed-high-performance.png";
import BkdImagePath from "../assets/img/bkd-demo-landing-pg.jpg";

const Demo = () => {
  const demoPageFormRef = useRef();
  const closedemoPageFormRef = useRef();
  const successCloudEbookButtonRef = useRef();
  const ebookDownloadButtonRef = useRef();
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendFail, setSendFail] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    async function submitDemoPageForm() {
      let demoSuccess = false;
      const userFname = document.getElementById("txt_demopg_firstname").value;
      const userLname = document.getElementById("txt_demopg_lastname").value;
      const userEmail = document.getElementById("txt_demopg_email").value;
      const userRole = document.getElementById("txt_demopg_role").value;
      const userCompany = document.getElementById("txt_demopg_company").value;

      const zohoForm = {
        postUrl:
          "https://forms.zohopublic.com/dmoore3/form/IntrapriseLandingPage/formperma/FLjhJ7zY-B849gWi0IMeJjHUZUh0R5Zu-s0LFNXK4z4/htmlRecords/submit",
        formFields: {
          zf_referrer_name: "",
          zf_redirect_url: "",
          zc_gad: "",
          Name_First: userFname,
          Name_Last: userLname,
          SingleLine1: userCompany,
          SingleLine: userRole,
          Email: userEmail,
        },
      };

      // this sends the email via api
      let apiObj = new Api();
      let sendResults = await apiObj.sendZohoForm(zohoForm, "spinner-demopage");

      switch (sendResults?.message.toUpperCase()) {
        case "SUCCESS":
          demoSuccess = true;
          setSendSuccess(true);
          break;
        default:
          setSendFail(true);
          setErrorMsg(sendResults?.message);
      }

      if (demoSuccess) {
        demoPageFormRef.current.classList.remove("was-validated");
        demoPageFormRef.current.reset();
      }
    }

    document
      .getElementById("but_demopg_contactSubmit")
      .addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (demoPageFormRef.current.checkValidity()) {
          submitDemoPageForm();
        }
        demoPageFormRef.current.classList.add("was-validated");
      });
  }, []);

  return (
    <Layout>
      <Head
        pageTitle="Let's start something big together."
        title="Let's start something big together."
        path="/demo"
        description="Request your strategic review."
        imageUrl="https://cms-imagebucket.s3.amazonaws.com/full/IntrapriseFeaturedImage.png"
        type="website"
        datePublished="10/11/2022"
        bodyAttributes={{ class: "d-flex flex-column h-100" }}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
								body {
									background-image: url(${BkdImagePath});
									background-size: cover;
									background-repeat: no-repeat;
									background-color: #0D0D0D;
								}
								form#form_demopg input[type='text'].form-control, form#form_demopg input[type='email'].form-control, form#form_demopg input[type='phone'].form-control {
									border-color: #DF732D;
									border-width: 2px;
								}
                section {
                    padding: 3rem 0 5rem 0;
                }
                @media (max-width: 576px) {
                    section {
                        padding: 2rem 0;
                    }
                }
            `,
        }}
      />
      <main>
        <div className="container">
          <section className="clearfix">
            <div class="row">
              <div class="col-12">
                <h1 className="text-light">
                  Let's start something big together.
                </h1>
                <h3 className="text-primary my-3">
                  <em>Lead with Strategy. Enable with Technology. Drive with Data.</em>
                </h3>
                <div class="d-flex align-items-center my-1">
                  <img
                    src={IconOngoingBigData}
                    alt="Ongoing Big Data & Digital Asset Review & Analysis"
                    width="50"
                    height="50"
                    className="me-2"
                  />
                  <div class="d-flex align-items-center">
                    <h4 class="my-0 py-0 align-middle text-light">
                      Ongoing Big Data &amp; Digital Asset Review &amp; Analysis
                    </h4>
                  </div>
                </div>
                <div class="d-flex align-items-center my-1">
                  <img
                    src={IconStrategicPlanning}
                    alt="nformed High Performance Computing & Analytics"
                    width="50"
                    height="50"
                    className="me-2"
                  />
                  <div class="d-flex align-items-center">
                    <h4 class="my-0 py-0 align-middle text-light">
                      Informed High Performance Computing &amp; Analytics
                    </h4>
                  </div>
                </div>
                <div class="d-flex align-items-center my-1">
                  <img
                    src={IconInformedHighPerf}
                    alt="Custom Strategic Appraisal & Planning"
                    width="50"
                    height="50"
                    className="me-2"
                  />
                  <div class="d-flex align-items-center">
                    <h4 class="my-0 py-0 align-middle text-light">
                      Custom Strategic Appraisal &amp; Planning
                    </h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3 ms-lg-5">
              <div class="col-md-7">
                <form
                  id="form_demopg"
                  className="needs-validation"
                  novalidate
                  ref={demoPageFormRef}
                >
                  <div className="row">
                    <div className="col-md-6 form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="txt_demopg_firstname"
                        title="First Name"
                        placeholder="Enter your first name..."
                        aria-describedby="FirstName"
                        required
                      />
                      <label for="txt_demopg_firstname" className="ms-2">
                        First Name
                      </label>
                      <div className="invalid-feedback">
                        Please provide your first name.
                      </div>
                    </div>
                    <div className="col-md-6 form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="txt_demopg_lastname"
                        title="Last Name"
                        placeholder="Enter your last name..."
                        aria-describedby="LastName"
                        required
                      />
                      <label for="txt_demopg_lastname" className="ms-2">
                        Last Name
                      </label>
                      <div className="invalid-feedback">
                        Please provide your last name.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 form-floating mb-2">
                      <input
                        type="email"
                        className="form-control"
                        id="txt_demopg_email"
                        title="Email"
                        placeholder="Enter your email..."
                        aria-describedby="Email"
                        required
                      />
                      <label for="txt_demopg_email" className="ms-2">
                        Email address
                      </label>
                      <div className="invalid-feedback">
                        Please provide your email.
                      </div>
                    </div>
                    <div className="col-md-6 form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="txt_demopg_role"
                        placeholder="Enter your role..."
                        aria-describedby="Role"
                        required
                      />
                      <label for="txt_demopg_role" className="ms-2">
                        Role
                      </label>
                      <div className="invalid-feedback">
                        Please provide your Role.
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        id="txt_demopg_company"
                        title="Company"
                        placeholder="Company"
                        aria-describedby="Company"
                        required
                      />
                      <label for="txt_demopg_company" className="ms-2">
                        Company
                      </label>
                      <div className="invalid-feedback">
                        Please provide your company.
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="but_demopg_contactSubmit"
                      >
                        <i
                          className="spinner-border spinner-border-sm buttonspinner"
                          role="status"
                          id="spinner-demopage"
                        ></i>{" "}
                        <span className="text-uppercase fw-bold">
                          Request your
                          <br />
                          strategic review
                        </span>
                      </button>
                    </div>
                  </div>
                  {sendSuccess && (
                    <div
                      className="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      <i className="bi bi-check-circle-fill me-2"></i>
                      <strong>
                        Thank you for your interest in Intraprise.
                      </strong>
                      <br />A representative will contact you shortly to
                      schedule a strategic review.
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}
                  {sendFail && (
                  	<div class="alert alert-danger" role="alert">
                  		<strong>Error:</strong> {errorMsg}
                  	</div>
                  )}

                </form>
              </div>
              <div class="col-md-5"></div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default Demo;
