import React from 'react';
import Home from '../components/Home';
import Head from "../components/Head";
import Layout from "../components/Layout";

const Index = () => {

    return (
        <Layout>
          <Head pageTitle="Intraprise Solutions - Digital Transformation Technology Services" title="Intraprise Solutions - Digital Transformation Technology Services" path="/" description="We commit. We deliver. We transform. For us, it's not just about technology; it's about people." imageUrl="https://cms-imagebucket.s3.amazonaws.com/full/IntrapriseFeaturedImage.png" type="website" datePublished="02/17/2022" />
          <Home />
        </Layout>
    )
}

export default Index;

