import React, { useEffect, useRef, useState } from "react";
import Api from "../components/Api";
import Layout from "../components/Layout";
import Head from "../components/Head";
import CloudIllustration from "../assets/img/illus-cloud.jpg";
import Icon85pct from "../assets/img/icon-85pct.png";
import BkdVideoPath from "../assets/video/cloud-computing-bkd-vid.mp4";

const CloudTransformation = () => {
  const [windowWidth, updateWindowDimensions] = useState(0);
  const cloudTransformationFormRef = useRef();
  const closecloudTransformationFormRef = useRef();
  const successCloudTransformationButtonRef = useRef();

  useEffect(() => {
    // resize
    updateWindowDimensions(window.innerWidth);
    window.addEventListener("resize", () => {
      updateWindowDimensions(window.innerWidth);
    });

    // form
    let cloudTransformationForm = cloudTransformationFormRef.current;
    let closecloudTransformationForm = closecloudTransformationFormRef.current;
    let successCloudTransformationButton = successCloudTransformationButtonRef.current;

    async function submitCloudTransformationForm() {
      const userFname = document.getElementById("txt_cloudtransformation_firstname").value;
      const userLname = document.getElementById("txt_cloudtransformation_lastname").value;
      const userEmail = document.getElementById("txt_cloudtransformation_email").value;
      const userPhone = document.getElementById("txt_cloudtransformation_phone").value;
      const userCompany = document.getElementById("txt_cloudtransformation_company").value;
      const userRole = document.getElementById("txt_cloudtransformation_role").value;

      const zohoForm = {
        postUrl:
          "https://forms.zohopublic.com/dmoore3/form/CloudLandingPage/formperma/l2OEMsnoGKICYXsXsX-Yb1uedvGGHA_bTELDyWk9_CA/htmlRecords/submit",
        formFields: {
          zf_referrer_name: "",
          zf_redirect_url: "",
          zc_gad: "",
          Name_First: userFname,
          Name_Last: userLname,
          SingleLine: userCompany,
          SingleLine1: userRole,
          PhoneNumber_countrycode: userPhone,
          Email: userEmail,
        },
      };

      // this sends the email via api
      let apiObj = new Api();
      let sendResults = await apiObj.sendZohoForm(zohoForm,"spinner-cloudtransformation");

      switch (sendResults.message.toUpperCase()) {
        case "SUCCESS":
          closecloudTransformationForm.click();
          successCloudTransformationButton.click();
          break;
        default:
        // @todo - fail code block
      }

      cloudTransformationForm.classList.remove("was-validated");
      cloudTransformationForm.reset();
    }

    document
      .getElementById("but_cloudtransformation_contactSubmit")
      .addEventListener("click", (event) => {
        event.preventDefault();
        event.stopPropagation();
        const cloudTransformationForm = event.target.form;
        if (cloudTransformationForm.checkValidity()) {
          submitCloudTransformationForm();
        }
        cloudTransformationForm.classList.add("was-validated");
      });
  }, []);

  useEffect(() => {
    const navbarCollapsible = document.body.querySelector("#mainNav");
    var headerHeight = navbarCollapsible.offsetHeight + "px";
    const videoJumbo = document.body.querySelector("#video-jumbotron");
    videoJumbo.style.marginTop = headerHeight;
  }, [windowWidth]);

  return (
    <Layout>
      <Head
        pageTitle="Transform Your Business with Cloud Computing."
        title="Transform Your Business with Cloud Computing."
        path="/cloud-transformation"
        description="Unleash the power of the Cloud with Intraprise's Cloud Transformation Solutions"
        imageUrl="https://cms-imagebucket.s3.amazonaws.com/full/Copy_of_Intraprise_Graphics_for_Socials_24_.png"
        type="website"
        datePublished="04/19/2023"
        bodyAttributes={{ class: "d-flex flex-column h-100" }}
      />
      <style
        dangerouslySetInnerHTML={{
          __html: `
					main > .container {
						padding: 0px 15px 0;
					}

					.jumbotron {
						position: relative;
						overflow: hidden;
						background-color:black;
					}
					.jumbotron video {
						position: absolute;
						z-index: 1;
						height: auto;
						width: auto;
						min-height: 100%;
						min-width:100%;
						left: 50%;
						top: 50%;
						transform: translate3d(-50%, -50%, 0);
					}
					.jumbotron .container {
						z-index: 2;
						position: relative;
					}
					.orange-box {
						border-style: solid;
						border-color: #f45600;
						border-width: 3px;
						width: 100%;
						padding: 1em;
						box-shadow: 10px 10px 0px 0px #f45600;
					}
					.dark-card {
						background-color: #737373;
					}
					.dark-card hr {
						height: 3px;
						color: #ff7b33;
						border: 0;
					}
          section {
            padding: 3rem 0 5rem 0;
          }
          @media (max-width: 576px) {
            section {
              padding: 2rem 0;
            }
          }
          `,
        }}
      />

      <main>
        <div className="jumbotron jumbotron-fluid" id="video-jumbotron">
          <video autoplay="true" muted loop="true">
            <source src={BkdVideoPath} type="video/mp4" />
          </video>

          <div
            className="container text-white py-5"
            data-aos="fade-down"
            data-aos-once="true"
            data-aos-duration="1000"
          >
            <div className="py-0 py-lg-3 py-xl-3">
              <h1 className="display-4">
                Transform Your Business with Cloud Computing
              </h1>
              <h3 className="text-primary my-3">
                Unleash the power of the Cloud with <br />
                Intraprise's Cloud Transformation Solutions
              </h3>
            </div>
          </div>
        </div>

        <section className="clearfix">
          <div className="container">
            <div class="row">
              <div class="col-md-7">
                <h3 className="text-primary my-3">
                  Cloud Computing is Crucial for Business Growth
                </h3>
                <p>
                  So why are so many companies struggling to take advantage of
                  the benefits of cloud-native technologies? We can help you
                  adapt your systems to a serverless computing environment, with
                  minimal disruption.
                </p>
                <div
                  className="mt-4 text-center"
                  data-aos="fade-up"
                  data-aos-once="true"
                >
                  <button className="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#modal_cloudtransformation">
                    Work with Us
                  </button>
                </div>
                <div className="d-lg-none d-xl-none d-sm-inline-block d-sm-inline-block">
                  <p>&nbsp;</p>
                </div>
              </div>
              <div class="col-md-5 py-0">
                <div className="orange-box mt-4 mt-sm-5">
                  <span className="text-primary">
                    <img
                      src={Icon85pct}
                      height="80"
                      width="80"
                      style={{ marginTop: "-70px" }}
                    />
                    of organizations will &quot;embrace a cloud-first
                    principle&quot; that will demand the use of
                    &quot;cloud-native architectures and technologies&quot; by
                    2025.
                  </span>
                  <div className="d-block text-primary text-end">
                    Source: Gartner
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light clearfix">
          <div className="container">
            <h3
              className="text-primary text-center my-3"
              data-aos="fade-down"
              data-aos-once="true"
            >
              Our Services
            </h3>

            <div class="row" data-aos="fade-up" data-aos-once="true">
              <div className="col-lg-4 mb-3 d-flex align-items-stretch">
                <div
                  className="card dark-card rounded-3"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title text-center text-white">
                      Rapid Assessment Services
                    </h5>
                    <hr className="mx-3" />
                    <p className="card-text mb-4 text-center text-white">
                      Providing a cost effective, low risk, short duration
                      approach to diagnose and resolve the source(s) of
                      performance and expense leakage.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-3 d-flex align-items-stretch">
                <div
                  className="card dark-card rounded-3"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title text-center text-white">
                      Refactoring Services
                    </h5>
                    <hr className="mx-3" />
                    <p className="card-text mb-4 text-center text-white">
                      Providing subject matter expertise, experience, and
                      engineering resources needed to execute the optimization
                      plan created during Rapid Assessment.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-3 d-flex align-items-stretch">
                <div
                  className="card dark-card rounded-3"
                  data-aos="zoom-in"
                  data-aos-once="true"
                >
                  <div className="card-body d-flex flex-column">
                    <h5 className="card-title text-center text-white">
                      Strategy Consultation
                    </h5>
                    <hr className="mx-3" />
                    <p className="card-text mb-4 text-center text-white">
                      Providing strategic planning for how to approach Cloud
                      optimization. Troubleshoot unique challenges, innovate,
                      and creatively overcome obstacles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="clearfix">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3 className="text-primary text-center">
                  Failed Cloud-Migration is Expensive. Intraprise Can Help.
                </h3>
                <p>
                  <img
                    src={CloudIllustration}
                    height="259"
                    width="400"
                    className="float-end img-fluid"
                  />
                </p>
                <p>
                  Our team of cloud fluent software engineers and technologists
                  perform assessments that identify areas of weakness to
                  identify how to improve efficiency, refine your strategy, and
                  reduce unnecessary cloud-spend. This will allow your business
                  to:
                </p>
                <ul className="checkmarks">
                  <li>
                    Rapidly adapt with access to advanced technologies only
                    available to very well capitalized companies with the
                    financial capacity to invest in costly technologies and
                    digital infrastructure
                  </li>
                  <li>
                    Realize the financial benefits of just in time scalability -
                    Pay for what you use while scaling your digital
                    infrastructure as the business grows
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-dark test-white clearfix">
          <h3 className="text-center text-white">Migrating to the cloud?</h3>
          <div className="mt-4 text-center">
						<button className="btn btn-primary btn-lg" data-bs-toggle="modal" data-bs-target="#modal_cloudtransformation">
							Talk to an Expert
            </button>
          </div>
        </section>
      </main>

      {/* contact form modals */}

      <div
        className="modal fade"
        id="modal_cloudtransformation"
        tabindex="-1"
        aria-labelledby="cloudTransModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-start" id="cloudTransModalLabel">
                Ready to get started?
              </h5>
              <div>
                <div className="d-none d-lg-inline">
                  <a
                    href="https://twitter.com/intraprise"
                    target="_blank"
                    rel="noopener"
                    title="visit our Twitter"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                  &nbsp;{" "}
                  <a
                    href="https://www.linkedin.com/company/intraprise-solutions"
                    className="me-3"
                    target="_blank"
                    rel="noopener"
                    title="visit our LinkedIn"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closecloudTransformationFormRef}
                ></button>
              </div>
            </div>
            <div className="modal-body">
              <form
                id="form_cloudtransformation"
                className="needs-validation"
                novalidate
                ref={cloudTransformationFormRef}
              >
                <p>
                  Please submit your contact information below. An Intraprise
                  team member will be in touch soon.
                </p>

                <div className="row">
                  <div className="col-md-6 form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="txt_cloudtransformation_firstname"
                      title="First Name"
                      placeholder="Enter your first name..."
                      aria-describedby="FirstName"
                      required
                    />
                    <label for="txt_cloudtransformation_firstname">
                      First Name
                    </label>
                    <div className="invalid-feedback">
                      Please provide your first name.
                    </div>
                  </div>
                  <div className="col-md-6 form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="txt_cloudtransformation_lastname"
                      title="Last Name"
                      placeholder="Enter your last name..."
                      aria-describedby="LastName"
                      required
                    />
                    <label for="txt_cloudtransformation_lastname">
                      Last Name
                    </label>
                    <div className="invalid-feedback">
                      Please provide your last name.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 form-floating mb-2">
                    <input
                      type="email"
                      className="form-control"
                      id="txt_cloudtransformation_email"
                      title="Email"
                      placeholder="Enter your email..."
                      aria-describedby="Email"
                      required
                    />
                    <label for="txt_cloudtransformation_email">
                      Email address
                    </label>
                    <div className="invalid-feedback">
                      Please provide your email.
                    </div>
                  </div>
                  <div className="col-md-6 form-floating mb-2">
                    <input
                      type="phone"
                      className="form-control"
                      id="txt_cloudtransformation_phone"
                      placeholder="Enter your phone..."
                      aria-describedby="Phone"
                    />
                    <label for="txt_cloudtransformation_phone">
                      Phone Number
                    </label>
                  </div>
                </div>
                <div className="row">
                  <div className="col form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="txt_cloudtransformation_company"
                      title="Company"
                      placeholder="Company"
                      aria-describedby="Company"
                      required
                    />
                    <label for="txt_cloudtransformation_company">Company</label>
                    <div className="invalid-feedback">
                      Please provide your company.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="txt_cloudtransformation_role"
                      title="Role"
                      placeholder="Role..."
                      aria-describedby="Role"
                      required
                    />
                    <label for="txt_cloudtransformation_role">Role</label>
                    <div className="invalid-feedback">
                      Please provide your role.
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col">
                    <button type="submit" className="btn btn-primary form-control" id="but_cloudtransformation_contactSubmit">
                      <i className="spinner-border spinner-border-sm buttonspinner" role="status" id="spinner-cloudtransformation"></i> Send
                    </button>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col">
                    <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-cloudtransformation-success">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Thank you</h5>
              <button
                type="button"
                className="btn invisible"
                data-bs-toggle="modal"
                data-bs-target="#modal-cloudtransformation-success"
                ref={successCloudTransformationButtonRef}
              >
                o
              </button>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <p>
                A member of the Intraprise team will be in touch soon. We look
                forward to working with you.
              </p>
              <button type="button" className="btn btn-secondary form-control mt-2" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CloudTransformation;
