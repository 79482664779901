import React, {useEffect, useRef} from 'react';
import Api from './Api';

const OptOutModal = () => {

  const optOutFormRef = useRef();
  const closeoptOutFormRef = useRef();
  const successOptOutButtonRef = useRef();


  useEffect(() => {

   // form
	 let optOutForm = optOutFormRef.current;
	 let closeoptOutForm = closeoptOutFormRef.current;
	 let successOptOutButton = successOptOutButtonRef.current;

	 async function submitOptOutForm() {
		 const userFname = document.getElementById("txt_optout_firstname").value;
		 const userLname = document.getElementById("txt_optout_lastname").value;
		 const userEmail = document.getElementById("txt_optout_email").value;

		 const zohoForm = {
			 postUrl:
				 "https://forms.zohopublic.com/dmoore3/form/IntrapriseDoNotSell/formperma/KlRPfwQPdC4wPtzq93XOUsi1fet2iyYPS-icMywnt5A/htmlRecords/submit",
			 formFields: {
				 zf_referrer_name: "",
				 zf_redirect_url: "",
				 zc_gad: "",
				 Name_First: userFname,
				 Name_Last: userLname,
				 Email: userEmail,
			 },
		 };

		 // this sends the email via api
		 let apiObj = new Api();
		 let sendResults = await apiObj.sendZohoForm(zohoForm,"spinner-optout");

		 switch (sendResults.message.toUpperCase()) {
			 case "SUCCESS":
				 closeoptOutForm.click();
				 successOptOutButton.click();
				 break;
			 default:
			 // @todo - fail code block
		 }

		 optOutForm.classList.remove("was-validated");
		 optOutForm.reset();
	 }

	 document
		 .getElementById("but_optout_contactSubmit")
		 .addEventListener("click", (event) => {
			 event.preventDefault();
			 event.stopPropagation();
			 const optOutForm = event.target.form;
			 if (optOutForm.checkValidity()) {
				 submitOptOutForm();
			 }
			 optOutForm.classList.add("was-validated");
		 });
    }, []);



    return (
        <>

      {/* contact form modals */}

      <div
        className="modal fade"
        id="modal_optout"
        tabindex="-1"
        aria-labelledby="optOutModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-start" id="optOutModalLabel">
                Opt-out
              </h5>
              <div>
                <div className="d-none d-lg-inline">
                  <a
                    href="https://twitter.com/intraprise"
                    target="_blank"
                    rel="noopener"
                    title="visit our Twitter"
                  >
                    <i className="bi bi-twitter"></i>
                  </a>
                  &nbsp;{" "}
                  <a
                    href="https://www.linkedin.com/company/intraprise-solutions"
                    className="me-3"
                    target="_blank"
                    rel="noopener"
                    title="visit our LinkedIn"
                  >
                    <i className="bi bi-linkedin"></i>
                  </a>
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeoptOutFormRef}
                ></button>
              </div>
            </div>
            <div className="modal-body">
              <form
                id="form_optout"
                className="needs-validation"
                novalidate
                ref={optOutFormRef}
              >
                <p>
                  Please complete this form to stop the sharing of information that may be related to you. We will only use the information to fulfill and maintain records of your request.
                </p>

                <div className="row">
                  <div className="col-md-6 form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="txt_optout_firstname"
                      title="First Name"
                      placeholder="Enter your first name..."
                      aria-describedby="FirstName"
                      required
                    />
                    <label for="txt_optout_firstname">
                      First Name
                    </label>
                    <div className="invalid-feedback">
                      Please provide your first name.
                    </div>
                  </div>
                  <div className="col-md-6 form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      id="txt_optout_lastname"
                      title="Last Name"
                      placeholder="Enter your last name..."
                      aria-describedby="LastName"
                      required
                    />
                    <label for="txt_optout_lastname">
                      Last Name
                    </label>
                    <div className="invalid-feedback">
                      Please provide your last name.
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 form-floating mb-2">
                    <input
                      type="email"
                      className="form-control"
                      id="txt_optout_email"
                      title="Email"
                      placeholder="Enter your email..."
                      aria-describedby="Email"
                      required
                    />
                    <label for="txt_optout_email">
                      Email address
                    </label>
                    <div className="invalid-feedback">
                      Please provide your email.
                    </div>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col">
                    <button type="submit" className="btn btn-primary form-control" id="but_optout_contactSubmit">
                      <i className="spinner-border spinner-border-sm buttonspinner" role="status" id="spinner-optout"></i> Send
                    </button>
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="col">
                    <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="modal-optout-success">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"></h5>
              <button
                type="button"
                className="btn invisible"
                data-bs-toggle="modal"
                data-bs-target="#modal-optout-success"
                ref={successOptOutButtonRef}
              >
                o
              </button>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body text-center">
              <p>
                Opt-Out request received.
              </p>
              <button type="button" className="btn btn-secondary form-control mt-2" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

        </>

    );
}

export default OptOutModal;
