import React, {useEffect, useRef} from 'react';
import { Link } from 'gatsby';
import intrapriseLogo from "../assets/img/logo_intraprise_sq.png";

const Topnav = () => {

    const headerRef = useRef();

    useEffect(() => {

        let selectHeader = headerRef.current;

        // Navbar shrink function
        var navbarShrink = function () {

            const navbarCollapsible = document.body.querySelector('#mainNav');
            if (!navbarCollapsible) {
                return;
            }
            if (window.scrollY === 0) {
                navbarCollapsible.classList.remove('navbar-shrink')
                document.querySelector("a.navbar-brand img").classList.add('rotate');        
            } else {
                navbarCollapsible.classList.add('navbar-shrink');
                document.querySelector("a.navbar-brand img").classList.remove('rotate');
            }

            // my own lame scrollspy
            let position = window.scrollY + 200;
            let navbarlinks = document.querySelectorAll('a.scrollto');
            navbarlinks.forEach(navbarlink => {
                if (!navbarlink.hash) return;
                let section = document.querySelector(navbarlink.hash);
                if (!section) return;
                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    activateLinkParent(navbarlink);
                    navbarlink.classList.add('active')
                } else {
                    navbarlink.classList.remove('active')
                }
            });
        
        };
        
        
        // Collapse responsive navbar when toggler is visible
        const navbarToggler = document.body.querySelector('.navbar-toggler');
        const responsiveNavItems = [].slice.call(
            document.querySelectorAll('#navbarNavDropdown .nav-link:not(.dropdown-toggle), #navbarNavDropdown .dropdown-item')
        );

        responsiveNavItems.map(function (responsiveNavItem) {

            if (window.location.pathname !== "/") {
                if (responsiveNavItem.hash.indexOf('#') === 0) {
                     // this to an absolute link (pointing to the correct section on the hompage)
                    responsiveNavItem.href = "/" + responsiveNavItem.hash;
                }
            }

            responsiveNavItem.addEventListener('click', () => {
                if (window.getComputedStyle(navbarToggler).display !== 'none') {
                    navbarToggler.click();
                }
            });
        });

        // scrollspy shiz
        const activateLinkParent = (navLink) => {
            const topLevelNavLinks = document.querySelectorAll('#mainNav ul.navbar-nav a.nav-link');
            topLevelNavLinks.forEach(tll => {
                tll.classList.remove('active');
            });
            let parentEl = navLink.parentElement.parentElement;
            if (parentEl?.tagName === "UL") {
                let prvSibling = parentEl.previousElementSibling;
                if (prvSibling?.tagName === "A" && prvSibling?.classList.contains("dropdown-toggle")) {
                    prvSibling.classList.add('active')
                }
            }	
        }

        // Shrink the navbar when page is scrolled
        document.addEventListener('scroll', navbarShrink);
        navbarShrink();
        
    }, []);

    useEffect(() => {

        let selectHeader = headerRef.current;

        const scrollToHash = () => {
            //alert ("i am loaded")
            if (window.location.pathname !== "/") {
                // hilight knowledge exchange
                if (window.location.pathname.indexOf("/knowledge-exchange") > -1) {
                    const keLink = document.querySelector("a.nav-link[href='/knowledge-exchange']");
                    if (keLink) {
                        keLink.classList.add("active");
                    }
                }
            }
            if (window.location.hash) {
                // scrollspy stuff
                const navbarToggler = document.body.querySelector('.navbar-toggler');
                const scrollToEl = document.querySelector(window.location.hash);
                let offset = (selectHeader.offsetHeight)/2;
                if (window.getComputedStyle(navbarToggler).display !== 'none') {
                    offset = 20;
                }

                if (!scrollToEl) return;
                let elementPos = scrollToEl.offsetTop;
                window.scrollTo({
                  top: elementPos - offset,
                  behavior: 'smooth'
                })
            }
        }
        scrollToHash();
    });


    return (
        <nav className="navbar navbar-expand-lg navbar-dark fixed-top" id="mainNav" ref={headerRef}>
        <div className="container">
            <Link className="navbar-brand" to="/#pagetop"><img src={intrapriseLogo} className="rotate" height="50" width="50" alt="..." /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            Menu
            <i className="bi bi-list"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">

                    <li className="nav-item"><a className="nav-link scrollto" href="#services">Services</a></li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">About Us</a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                            <li><a className="dropdown-item scrollto" href="#about">About Intraprise</a></li>
                            <li><a className="dropdown-item scrollto" href="#values">Our Core Values</a></li>
                            <li><a className="dropdown-item scrollto" href="#timeline">25+ Years of Experience</a></li>
                            <li><a className="dropdown-item scrollto" href="#team">Our Amazing Team</a></li>
                        </ul>
                    </li>
                    <li className="nav-item"><a className="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#modal_contact">Contact</a></li>
                    <li className="nav-item"><Link className="nav-link" to="/knowledge-exchange">Knowledge Exchange</Link></li>

                </ul>
                <ul className="navbar-nav sm-icons mr-0">
                    <li className="nav-item d-none d-lg-inline"></li>
                    <li className="nav-item"><a className="nav-link px-1" href="https://twitter.com/intraprise" target="_blank" title="visit our Twitter"><i className="bi bi-twitter"></i></a></li>
                    <li className="nav-item"><a className="nav-link px-1" href="https://www.linkedin.com/company/intraprise-solutions" target="_blank" title="visit our LinkedIn"><i className="bi bi-linkedin"></i></a></li>
                </ul>
            </div>
        </div>
        </nav>
    );
}

export default Topnav;
