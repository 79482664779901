import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '../assets/img/favicon.png';


const Head = ({ pageTitle, title, path, description, imageUrl, type, datePublished }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    siteUrl,
                    title,
                    author
                }
            }
        }
    `);

    // remove html and double quotes  from description
    const stripHTMLregex = /(<([^>]+)>)/ig;
    const multiSpaceRegex = /\s\s+/g;
    const stripDblQuoteRegex = /["]+/g;
    const cleanDescription = description.replace(stripHTMLregex, " ").replace(multiSpaceRegex, " ").trim().replace(stripDblQuoteRegex, "");

    // convert date to iso
    //const publishDate = new Date(datePublished);
    //const isoDateStr = publishDate.toISOString();

    return (
        <>
            <Helmet title={`${pageTitle} | ${data.site.siteMetadata.title}`}
            bodyAttributes={{class: 'd-flex flex-column h-100'}}/>
            <Helmet>
                <link rel="icon" href={favicon} />
​
                <meta name="twitter:card" content="summary_large_image"></meta>
                <meta name="twitter:title" content={title}></meta>
                <meta name="twitter:description" content={cleanDescription}></meta>
                <meta name="twitter:image" content={imageUrl}></meta>
​
                <meta property="og:locale" content="en_GB" />
                <meta property="og:site_name" content={data.site.siteMetadata.title} />
                <meta property="og:title" content={title}></meta>
                <meta property="og:url" content={data.site.siteMetadata.siteUrl + path}></meta>
                <meta property="og:description" content={cleanDescription}></meta>
                <meta property="og:image" content={imageUrl}></meta>
                <meta property="og:type" content={type} />
                <meta name="Description" content={cleanDescription}></meta>

                {/*-- OptanonConsentNoticeStart --*/}
				<script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="200d3158-c9e7-4861-a464-5a46726c63ad" ></script>
				{/*
                <script type="text/javascript">
                    function OptanonWrapper() { }
                </script>
                */}
                {/* -- OptanonConsentNoticeEnd -- */}

            </Helmet>
        </>
    )
}

export default Head