import React from 'react';
import iconCloudComputing from "../assets/img/icon-cloudcomputing.svg";
import iconHighPerf from "../assets/img/icon-highperformance.svg";
import iconBigData from "../assets/img/icon-bigdata.svg";
import iconSimulation from "../assets/img/icon-simulation.svg";
import iconQuotation from "../assets/img/icon-quotation.svg";
import iconEmpathy from "../assets/img/icon-empathy.svg";
import iconHumility from "../assets/img/icon-humility.svg";
import iconCuriosity from "../assets/img/icon-curiosity.svg";
import iconPerseverance from "../assets/img/icon-perseverance.svg";
import iconTimeline1 from "../assets/img/icon-1997-2002.svg";
import iconTimeline2 from "../assets/img/icon-2003-2009.svg";
import iconTimeline3 from "../assets/img/icon-2009-2013.svg";
import iconTimeline4 from "../assets/img/icon-2014-Now.svg";
import teamToddFisher from "../assets/img/team-toddfisher.png";
import teamJeffVV from "../assets/img/team-jvv.png";
import teamJoeBrown from "../assets/img/team-joebrown.png";
import teamLaura from "../assets/img/team-lauraroberto.png";
import teamKay from "../assets/img/team-kayvia.png";

const Home = () => {
    return (
        <>
            <header className="masthead" id="pagetop">
                <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-once="true">
                    <div className="masthead-heading text-uppercase">INTRAPRISE</div>
                    <div className="masthead-subheading">Change is constant. We are ready.</div>
                    <a className="btn btn-primary btn-xl text-uppercase" href="#services">Learn More</a>
                </div>
            </header>

            {/* Services Section */}
            <section className="page-section clearfix" id="services">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Services</h2>
                        <h3 className="section-subheading text-muted">Digital technology services and solutions tailored to the unique needs of each customer.</h3>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3" data-aos="zoom-in-down" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="d-flex">
                                <img src={iconCloudComputing} alt="Cloud Computing"
                                    className="flex-shrink-0 me-3 mt-0 rounded-circle" style={{width:"85px",height:"85px"}} />
                                <div>
                                    <h4 className="my-0">Cloud Computing</h4>
                                    <p>Strategic consulting and software engineering services to deliver legacy system transformation, core technology and platform integration, and system migration services.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3" data-aos="zoom-in-down" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="d-flex">
                                <img src={iconHighPerf} alt="High Performance Computing and Analytics"
                                    className="flex-shrink-0 me-3 mt-0 rounded-circle" style={{width:"85px",height:"85px"}} />
                                <div>
                                    <h4 className="my-0">High Performance Computing and Analytics</h4>
                                    <p>HPC-powered simulation programming services and solutions with integrated stochastic and non-stochastic models.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3" data-aos="zoom-in-up" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="d-flex">
                                <img src={iconBigData} alt="Big Data"
                                    className="flex-shrink-0 me-3 mt-0 rounded-circle" style={{width:"85px",height:"85px"}} />
                                <div>
                                    <h4 className="my-0">Big Data</h4>
                                    <p>Digital asset analysis and integration including data extraction, transformation, distribution and both semantic and system interoperability services and solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 mb-3" data-aos="zoom-in-up" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="d-flex">
                                <img src={iconSimulation} alt="Simulation Engineering Solutions"
                                    className="flex-shrink-0 me-3 mt-0 rounded-circle" style={{width:"85px",height:"85px"}} />
                                <div>
                                    <h4 className="my-0">Digital Transformation Consulting</h4>
                                    <p>Readiness assessment and planning services based on our Synapsys<sup><small>Dx</small></sup> methodology and model.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="text-center mt-4">Successful Digital Transformation requires a holistic perspective: People, Business Process, Culture, and Technology.</p>
                </div>
            </section>

            {/* About Section */}
            <section className="page-section bg-light clearfix" id="about">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">About Intraprise</h2>
                    </div>

                    <p className="dropcap" data-aos="fade-up" data-aos-once="true">We are a successful Digital Technology Services team with experienced technologists, software and data engineers, strategists and advisors driven by four core values: empathy, humility, curiosity, perseverance.</p>

                    <p data-aos="fade-up" data-aos-once="true">For a quarter century, Intraprise has built solutions that empower and transform businesses across markets. The people behind Intraprise have remained grounded in the idea that digital transformation is a process not an event, and it&rsquo;s about far more than deploying the latest technologies. Success demands we listen to understand and engage with empathy to deliver solutions tailored to the unique needs of each customer.</p>

                    <p data-aos="fade-up" data-aos-once="true">Our mission is to enable highly adaptive organizations&rsquo; ability to survive and thrive in a rapidly changing and uncertain digital ecosystem. We accomplish our mission by creatively combining our experience, passion, and record of transformative innovation with our customers&rsquo; domain expertise and perspective to deliver tomorrow&rsquo;s digital technology solutions today.</p>

                </div>
            </section>


            {/* Core Values Section */}
            <section className="page-section clearfix" id="values">
                <div className="container">

                    <div className="text-center mb-3">
                        <h2 className="section-heading text-uppercase">Our Core Values</h2>
                    </div>

                    <div id="carousel_fourValues" className="carousel carousel-dark slide" data-bs-ride="carousel" data-bs-touch="true">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carousel_fourValues" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carousel_fourValues" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carousel_fourValues" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carousel_fourValues" data-bs-slide-to="3" aria-label="Slide 4"></button>
                        </div>
                        <div className="carousel-inner">

                            <div className="carousel-item active my-2" data-bs-interval="5000">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <img src={iconEmpathy} alt="Empathy" className="me-2" width="50" height="50" />
                                    <div className="d-flex align-items-center">
                                        <h4 className="my-0 py-0 align-middle">Empathy</h4>
                                    </div>
                                </div>
                                <p className="text-center text-black">is the lens through which we come to truly know others.</p>
                                <div className="row">
                                    <div className="col-6 offset-3 border rounded">
                                        <div className="col d-flex align-items-start mt-3 mb-2">
                                            <div className="icon-square text-dark flex-shrink-0 me-3 d-none d-sm-block">
                                            <img src={iconQuotation} width="50" height="50"/>
                                            </div>
                                            <div className="text-start">
                                            <p><em>Accustom yourself to attend carefully to what is said by another. Try to inhabit the speaker&rsquo;s mind.</em></p>
                                            <p className="small text-end"><strong>&#8212; Marcus Aurelius</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item my-2" data-bs-interval="5000">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <img src={iconHumility} alt="Humility" className="me-2" width="50" height="50" />
                                    <div className="d-flex align-items-center">
                                        <h4 className="my-0 py-0 align-middle">Humility</h4>
                                    </div>
                                </div>
                                <p className="text-center text-black">closes your mouth and opens your mind.</p>
                                <div className="row">
                                    <div className="col-6 offset-3 border rounded">
                                        <div className="col d-flex align-items-start mt-3 mb-2">
                                            <div className="icon-square text-dark flex-shrink-0 me-3 d-none d-sm-block">
                                            <img src={iconQuotation} width="50" height="50"/>
                                            </div>
                                            <div className="text-start">
                                            <p><em>Real knowledge is to know the extent of one&rsquo;s ignorance.</em></p>
                                            <p className="small text-end"><strong>&#8212; Confucius</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item my-3" data-bs-interval="5000">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <img src={iconCuriosity} alt="Curiosity" className="me-2" width="50" height="50" />
                                    <div className="d-flex align-items-center">
                                        <h4 className="my-0 py-0 align-middle">Curiosity</h4>
                                    </div>
                                </div>
                                <p className="text-center text-black">exercises the power of &#34;Why&#34;?</p>
                                <div className="row">
                                    <div className="col-6 offset-3 border rounded">
                                        <div className="col d-flex align-items-start mt-3 mb-2">
                                            <div className="icon-square text-dark flex-shrink-0 me-3 d-none d-sm-block">
                                            <img src={iconQuotation} width="50" height="50"/>
                                            </div>
                                            <div className="text-start">
                                            <p><em>Millions saw the apple fall, but Newton was the one who asked why.</em></p>
                                            <p className="small text-end"><strong>&#8212; Bernard Baruch</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="carousel-item my-2" data-bs-interval="5000">
                                <div className="d-flex align-items-center justify-content-center mb-3">
                                    <img src={iconPerseverance} alt="Perseverance" className="me-2" width="50" height="50" />
                                    <div className="d-flex align-items-center">
                                        <h4 className="my-0 py-0 align-middle">Perseverance</h4>
                                    </div>
                                </div>
                                <p className="text-center text-black">is the required ingredient in accomplishment.</p>
                                <div className="row">
                                    <div className="col-6 offset-3 border rounded">
                                        <div className="col d-flex align-items-start mt-3 mb-2">
                                            <div className="icon-square text-dark flex-shrink-0 me-3 d-none d-sm-block">
                                            <img src={iconQuotation} width="50" height="50"/>
                                            </div>
                                            <div className="text-start">
                                            <p><em>In the realm of ideas everything depends on enthusiasm... in the real world all rests on perseverance.</em></p>
                                            <p className="small text-end"><strong>&#8212; Johann Wolfgang von Goethe</strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carousel_fourValues" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carousel_fourValues" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                </div>
            </section>


            {/* Timeline Section */}
            <section className="page-section clearfix bg-light" id="timeline">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">25+ Years of Experience</h2>
                        <h3 className="section-subheading text-muted">25+ years of transforming companies, industries, &#38; markets.</h3>
                    </div>
                    <ul className="timeline">
                        <li data-aos="fade-up" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={iconTimeline1} alt="1997-2002" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>1997 - 2002</h4>
                                </div>
                                <div className="timeline-body"><p className="text-muted">Web-Enabled Workflow &amp; Document Management Solution, Predecessor to Software as a Service</p></div>
                            </div>
                        </li>
                        <li className="timeline-inverted" data-aos="fade-up" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={iconTimeline2} alt="2003-2009" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>2003 - 2009</h4>
                                </div>
                                <div className="timeline-body"><p className="text-muted">High-Performance Grid Computing to Transform Complex Simulation &amp; 
                                    Decision Support Systems</p></div>
                            </div>
                        </li>
                        <li data-aos="fade-up" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={iconTimeline3} alt="2009-2013" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>2009 - 2013</h4>
                                </div>
                                <div className="timeline-body"><p className="text-muted">Big Data Extraction, Transformation &amp; Distribution in a Cloud-Based Computing Environment</p></div>
                            </div>
                        </li>
                        <li className="timeline-inverted" data-aos="fade-up" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="timeline-image"><img className="rounded-circle img-fluid" src={iconTimeline4 } alt="2014-Now" /></div>
                            <div className="timeline-panel">
                                <div className="timeline-heading">
                                    <h4>2014 - Now</h4>
                                </div>
                                <div className="timeline-body"><p className="text-muted">Strategic Advisory and Technology Consulting Services & Next Generation Behavioral Analytics</p></div>
                            </div>
                        </li>
                        <li className="timeline-inverted" data-aos="fade-up" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="timeline-image">
                                <h4>
                                <a href="#" className="text-white stretched-link" title="Contact Us" data-bs-toggle="modal" data-bs-target="#modal_contact">
                                    Be Part
                                    <br />
                                    Of Our
                                    <br />
                                    Story!
                                </a>
                                </h4>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            {/* Team Section */}
            <section className="page-section clearfix" id="team">
                <div className="container">
                    <div className="text-center">
                        <h2 className="section-heading text-uppercase">Our Amazing Team</h2>
                        <h3 className="section-subheading text-muted">We are successful Information Technology entrepreneurs, experienced data & software engineers, and skilled strategists, advisors, & operators. It&rsquo;s always our goal to listen to understand and view the world through our customer&rsquo;s point of view.</h3>
                    </div>
                </div>


                <div className="container">
                    <div className="row justify-content-md-center">
                        <div className="col-lg-auto">
                        </div>
                        <div className="col-lg-3" data-aos="flip-left" data-aos-easing="ease-out-back" data-aos-duration="1000" data-aos-once="true">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <div className="team-member">
                                        <img className="mx-auto rounded-circle" src={teamToddFisher} alt="Todd Fisher" />
                                        <h4>Todd Fisher</h4>
                                        <p className="text-muted">Chairman</p>
                                    </div>
                                </div>
                                <div className="flip-card-back p-3">
                                    <h4>Fun Fact:</h4>
                                    <p className="small">Todd loves to read, write, and &#34;run&#34; (more like a slow jog). In his bizzarro world, Seinfeld reruns are still &#34;must see TV.&#34; Fake gifts to celebrate Festivus are welcome – it happens to fall on his actual birthday.</p>
                                    <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/todd-fisher-b507175/" target="_blank"><i className="bi bi-linkedin"></i></a>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3" data-aos="flip-right" data-aos-duration="1000" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <div className="team-member">
                                        <img className="mx-auto rounded-circle" src={teamJeffVV} alt="Jeff VanVoorhis" />
                                        <h4>Jeff Van Voorhis</h4>
                                        <p className="text-muted">President and Director</p>
                                    </div>
                                </div>
                                <div className="flip-card-back p-3">
                                    <h4>Fun Fact:</h4>
                                    <p className="small">Jeff has backpacked across the globe, covering 100 miles of the Appalachian Trail, the Laugavegur Trail in Iceland, and north of the Arctic Circle in Norway.</p>
                                    <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/jeff-van-voorhis-334759/" target="_blank"><i className="bi bi-linkedin"></i></a>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3" data-aos="flip-left" data-aos-duration="1000" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <div className="team-member">
                                        <img className="mx-auto rounded-circle" src={teamJoeBrown} alt="Joe Brown" />
                                        <h4>Joe Brown</h4>
                                        <p className="text-muted">Chief Information Officer</p>
                                    </div>
                                </div>
                                <div className="flip-card-back p-3">
                                    <h4>Fun Fact:</h4>
                                    <p className="small">Joe has competed in nearly 500 races on running tracks, cross country courses, trails, and roads, from the 100-yard dash to the marathon. And yes, that’s yards, not meters - which means he started racing a long time ago. His favorite race distance is the mile.</p>
                                    <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/joe-brown-705149a/" target="_blank"><i className="bi bi-linkedin"></i></a>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-auto">
                        </div>
                    </div>
                    <div className="row justify-content-md-center me-3">
                        <div className="col-lg-auto">
                        </div>
                        <div className="col-lg-3" data-aos="flip-right" data-aos-duration="1000" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <div className="team-member">
                                        <img className="mx-auto rounded-circle" src={teamLaura} alt="Laura Roberto" />
                                        <h4>Laura Roberto</h4>
                                        <p className="text-muted">VP Business Development and Digital Engagement</p>
                                    </div>
                                </div>
                                <div className="flip-card-back p-3">
                                    <h4>Fun Fact:</h4>
                                    <p className="small">Laura loves to chase around her toddlers, watch episodes of The Office for the hundredth time, and go for a run whenever she can. She is happiest at the beach - her favorite spot is Cape May, New Jersey.</p>
                                    <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/lauracharrington/" target="_blank"><i className="bi bi-linkedin"></i></a>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3" data-aos="flip-left" data-aos-duration="1000" data-aos-easing="ease-out-back" data-aos-once="true">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                <div className="flip-card-front">
                                    <div className="team-member">
                                        <img className="mx-auto rounded-circle" src={teamKay} alt="Kay Via" />
                                        <h4>Kay Via</h4>
                                        <p className="text-muted">VP Operations and Sales Eyedog.US</p>
                                    </div> 
                                </div>
                                <div className="flip-card-back p-3">
                                    <h4>Fun Fact:</h4>
                                    <p className="small">A country girl at heart, Kay loves to travel and experience different cultures - especially the restaurants you don&rsquo;t hear about but are fun to find. But most important to Kay is her family; she loves large family gatherings when everyone can be together.</p>
                                    <a className="btn btn-dark btn-social mx-2" href="https://www.linkedin.com/in/kayvia/" target="_blank"><i className="bi bi-linkedin"></i></a>
                                    <a className="btn btn-dark btn-social mx-2" href="https://twitter.com/kay_via" target="_blank"><i className="bi bi-twitter"></i></a>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-auto">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 mx-auto text-center"><p className="large">Let us help you transform your business!</p><p className="large">Have questions? We&rsquo;d love to hear from you! Reach out to our team, and we will get back in touch soon!</p></div>
                    </div>

                    <div className="mt-4 text-center" data-aos="fade-up" data-aos-once="true">
                        <a className="btn btn-primary-outline btn-lg" href="#" data-bs-toggle="modal" data-bs-target="#modal_contact">Contact Us</a>
                    </div>

                </div>

            </section>

        </>
    );
}

export default Home;
