import React from 'react';
//import { StaticImage } from "gatsby-plugin-image";
import Head from '../components/Head';
import Layout from "../components/Layout";
import Archivelist from '../components/ArchiveList';
import Sharebuttons from '../components/Sharebuttons';
import Ebookwidget from '../components/EbookWidget';
import '../assets/css/blog.css';

const Post = ({ pageContext: { pgCtxt } }) => {

  return (
    <Layout>
    <Head pageTitle={pgCtxt.post.title} title={pgCtxt.post.title} path={'/knowledge-exchange/' + pgCtxt.post.id} description={pgCtxt.post.briefDescription} imageUrl={pgCtxt.post.image} type="website" datePublished={pgCtxt.post.date} />

    <main class="flex-shrink-1">
      <div className="container">

        <div className="blog-page-image mt-lg-4 ratio ratio-21x9" style={{backgroundImage: `url(${pgCtxt.post.image})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}>&nbsp;</div>

        <div className="row my-5">
          <div class="col-md-12">
            <h1>{pgCtxt.post.title}</h1>
            <div id="placeholder_pageCredits">
              <i class="bi bi-person-circle text-primary"></i> by {pgCtxt.post.author}, {new Date(pgCtxt.post.date).toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            </div>
          </div>
        </div>
        <div className="row post-section">
            <div className="col-md-9">
              { <div dangerouslySetInnerHTML={{ __html: pgCtxt.post.content.replace(/<img.*?\/>/, '') }} /> }
              <div className="my-3 text-end">
                <Sharebuttons title={pgCtxt.post.title} path={'/knowledge-exchange/' + pgCtxt.post.id} twitterHandle={'@intraprise'} tags={pgCtxt.post.keywords} image={pgCtxt.post.image} />
              </div>
            </div>
            <div className="col-md-3">
              <div id="placeholder_postList" className="p-3 bg-light rounded">


                <div className="p-1 mb-2 bg-light rounded">
                    <h5 className="text-center">Knowledge Exchange</h5>
                    <p className="mb-1 lh-sm text-center">Stay up-to-date and never miss a post.</p>
                    <p className="text-center"><a href="#" data-bs-target="#modal_subscribe" data-bs-toggle="modal" className="btn btn-primary-outline btn-sm rounded-3 border-1" title="Subscribe to Knowledge Exchange"><i class="bi bi-newspaper"></i> Subscribe to Knowledge Exchange</a></p>
                </div>

                <Ebookwidget padding="p-2" />
                <Archivelist props={
                {
                  allPosts: pgCtxt.allPosts,
                  postId: pgCtxt.post.id
                }
              }/></div>
            </div>
        </div>

      </div>
    </main>  


  </Layout>
  );


};

export default Post;