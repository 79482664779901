import React, {useEffect, useRef} from 'react';
import Api from '../components/Api';
import Layout from "../components/Layout";
import Head from "../components/Head";
import Sharebuttons from '../components/Sharebuttons';
import BookCover from "../assets/img/ebook-cloud-computing.png";
import IconEbookIntro from "../assets/img/icon-ebook-intro.png";
import IconEbookKeyElements from "../assets/img/icon-ebook-keyelements.png";
import IconEbookForecast from "../assets/img/icon-ebook-forecast.png";
import IconEbook from "../assets/img/icon-ebook.svg";
import EbookDownload from "../assets/pdf/Cloud Computing EBook.pdf";


const CloudSeries = () => {

    const cloudEbookFormRef = useRef();
    const closeCloudEbookFormRef = useRef();
    const successCloudEbookButtonRef = useRef();
    const ebookDownloadButtonRef = useRef();

    useEffect(() => {

        let cloudEbookForm = cloudEbookFormRef.current;
        let closeCloudEbookForm = closeCloudEbookFormRef.current;
        let successCloudEbookButton = successCloudEbookButtonRef.current;
        let ebookDownloadButton = ebookDownloadButtonRef.current;

        async function submitEBookForm() {
            const userFname = document.getElementById("txt_cloudebook_firstname").value;
            const userLname = document.getElementById("txt_cloudebook_lastname").value;
            const userEmail = document.getElementById("txt_cloudebook_email").value;
            const userPhone = document.getElementById("txt_cloudebook_phone").value;
            const userCompany = document.getElementById("txt_cloudebook_company").value;
            const userMessage = document.getElementById("txt_cloudebook_message").value;
            
            /*
            let postBody = {
                "website": "Intraprise",
                "name": userFname + " " + userLname,
                "phone": userPhone,
                "email": userEmail,
                "company": userCompany,
                "message": userMessage
            }
            */
            
            const zohoForm = {
                "postUrl": "https://forms.zohopublic.com/dmoore3/form/CloudSeries/formperma/q4YR6V6NZfRcu7ByapH0lrXMBun_Z2lGnLrJY9M_QkI/htmlRecords/submit",
                "formFields": {
                    "zf_referrer_name": "",
                    "zf_redirect_url": "",
                    "zc_gad": "",
                    "Name_First": userFname,
                    "Name_Last": userLname,
                    "SingleLine": userCompany,
                    "MultiLine": userMessage,
                    "PhoneNumber_countrycode": userPhone,
                    "Email": userEmail
                }
            }
            
            // this sends the email via api
            let apiObj = new Api();
            let sendResults = await apiObj.sendZohoForm(zohoForm, "spinner-cloudebook");
            //let sendResults = await apiObj.sendMail(postBody, "spinner-cloudebook");
            
            switch(sendResults.message.toUpperCase()) {
              case "SUCCESS":
                closeCloudEbookForm.click();
                successCloudEbookButton.click();
                const downloadTimer = setTimeout(() => ebookDownloadButton.click(), 1000);
                break;
              default:
                // @todo - fail code block
            }

            cloudEbookForm.classList.remove("was-validated");
            cloudEbookForm.reset();
        }

		document.getElementById("but_cloudebook_contactSubmit").addEventListener('click',      
			(event) => {
				event.preventDefault()
				event.stopPropagation()
				const cloudEbookForm = event.target.form;
				if (cloudEbookForm.checkValidity()) {
					submitEBookForm();
				}
				cloudEbookForm.classList.add('was-validated')
		});

    }, []);


    return (
        <Layout>
            <Head pageTitle="Cloud Computing E-Book" title="Cloud Computing E-Book" path="/cloud-series" description="Our free Cloud Computing E-Book takes a deep dive into the Cloud, unpacking our current understandings and misunderstandings. We explore the building blocks of the cloud and breakdown the essential elements of a well-rounded cloud computing strategy in today's rapidly changing digital economy." imageUrl="https://cms-imagebucket.s3.amazonaws.com/full/product-cloud-computing-ebook.jpg" type="website" datePublished="03/09/2022" bodyAttributes={{class: 'd-flex flex-column h-100'}} />
            <style dangerouslySetInnerHTML={{__html: `
                section {
                    padding: 9rem 0 5rem 0;
                }
                main > .container {
                    padding: 60px 15px 0;
                }
                @media (max-width: 576px) {
                    section {
                        padding: 5rem 0;
                    }
                }
            `}} />
            <main className="bg-dark ebook-bkd">
                <div className="container">

                    <section className="clearfix">

                        <div class="row">
                            <div class="col-12">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#modal_cloud_ebook" title="Download Now"><img src={BookCover} width="424" height="550" className="float-end img-fluid" data-aos="flip-right" data-aos-duration="1000" data-aos-easing="ease-out-back" data-aos-once="true" /></a>
                                <h1 className="text-light">Cloud Computing E-Book</h1>
                                <h3 className="text-light">Authored by Industry Expert, Todd J. Fisher</h3>

                                <p className="text-light">Our free Cloud Computing E-Book takes a deep dive into the Cloud, unpacking our current understandings and misunderstandings. We explore the building blocks of the cloud and breakdown the essential elements of a well-rounded cloud computing strategy in today's rapidly changing digital economy.</p>
                                <h3 className="text-light">WHAT TO EXPECT</h3>
                                <div class="d-flex align-items-center">
                                    <img src={IconEbookIntro} alt="Introduction to the cloud and the building blocks" width="66" height="66" />
                                    <div class="d-flex align-items-center">
                                        <h4 class="my-0 py-0 align-middle text-light">Introduction to the cloud and the building blocks</h4>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img src={IconEbookKeyElements} alt="Key elements for a well-rounded cloud computing strategy" width="66" height="66" />
                                    <div class="d-flex align-items-center">
                                        <h4 class="my-0 py-0 align-middle text-light">Key elements for a well-rounded cloud computing strategy</h4>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <img src={IconEbookForecast} alt="A forecast on cloud computing with growth insights" width="66" height="66" />
                                    <div class="d-flex align-items-center">
                                        <h4 class="my-0 py-0 align-middle text-light">A forecast on cloud computing with growth insights</h4>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <button className="btn btn-primary btn-large text-uppercase mb-3" data-bs-toggle="modal" data-bs-target="#modal_cloud_ebook">Download Now</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div className="col-12 my-3 text-end">
                                <Sharebuttons title="Cloud Computing E-Book" path={'/cloud-series'} twitterHandle={'@intraprise'} tags={'eBook, cloud, series'} image={'https://cms-imagebucket.s3.amazonaws.com/full/product-cloud-computing-ebook.jpg'} />
                            </div>
                        </div>

                    </section>

                </div>
            </main>

            <div className="modal fade" id="modal_cloud_ebook" tabindex="-1" aria-labelledby="ebookModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-start" id="ebookModalLabel">Download Our FREE eBook on Cloud Computing</h5>
                            <div>
                                <div className="d-none d-lg-inline">
                                    <a href="https://twitter.com/intraprise" target="_blank" rel="noopener" title="visit our Twitter"><i className="bi bi-twitter"></i></a>&nbsp; <a href="https://www.linkedin.com/company/intraprise-solutions" className="me-3" target="_blank" rel="noopener" title="visit our LinkedIn"><i className="bi bi-linkedin"></i></a>
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeCloudEbookFormRef}></button>
                            </div>
                        </div>
                        <div className="modal-body">
                        
                            <form id="form_cloud_ebook" className="needs-validation" novalidate ref={cloudEbookFormRef}>

                                <p>Complete this brief form to start your eBook download and learn more about Cloud Computing. Intraprise uses the information you provide to keep you updated on our newest content, products, and services. We will not share your information outside of our organization, and you can unsubscribe from communications at any time. </p>

                                <div className="row">
                                    <div className="col-md-6 form-floating mb-2">
                                        <input type="text" className="form-control" id="txt_cloudebook_firstname" title="First Name" placeholder="Enter your first name..." aria-describedby="FirstName" required />
                                        <label for="txt_cloudebook_firstname">First Name</label>
                                        <div className="invalid-feedback">
                                            Please provide your first name.
                                        </div>
                                    </div>
                                    <div className="col-md-6 form-floating mb-2">
                                        <input type="text" className="form-control" id="txt_cloudebook_lastname" title="Last Name" placeholder="Enter your last name..." aria-describedby="LastName" required />
                                        <label for="txt_cloudebook_lastname">Last Name</label>
                                        <div className="invalid-feedback">
                                            Please provide your last name.
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 form-floating mb-2">
                                        <input type="email" className="form-control" id="txt_cloudebook_email" title="Email" placeholder="Enter your email..." aria-describedby="Email" required />
                                        <label for="txt_cloudebook_email">Email address</label>
                                        <div className="invalid-feedback">
                                            Please provide your email.
                                        </div>
                                    </div>
                                    <div className="col-md-6 form-floating mb-2">
                                        <input type="phone" className="form-control" id="txt_cloudebook_phone" placeholder="Enter your phone..." aria-describedby="Phone" />
                                        <label for="txt_cloudebook_phone">Phone Number</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col form-floating mb-2">
                                        <input type="text" className="form-control" id="txt_cloudebook_company" title="Company" placeholder="Company" aria-describedby="Company" required/>
                                        <label for="txt_cloudebook_company">Company</label>
                                        <div className="invalid-feedback">
                                        Please provide your company.
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col form-floating mb-2">
                                        <textarea className="form-control" id="txt_cloudebook_message" title="Message" placeholder="Enter the how you found us..." aria-describedby="Message" required></textarea>
                                        <label for="txt_cloudebook_message">How did you find us?</label>
                                        <div className="invalid-feedback">
                                            How did you find us?
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">
                                        <button type="submit" className="btn btn-primary form-control" id="but_cloudebook_contactSubmit">
                                            <i className="spinner-border spinner-border-sm buttonspinner" role="status" id="spinner-cloudebook"></i> Send
                                        </button>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col">
                                        <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>

                            </form>

                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="modal-cloudebook-success">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Your Free eBook Download Will Start Soon</h5>
                            <button type="button" className="btn invisible" data-bs-toggle="modal" data-bs-target="#modal-cloudebook-success" ref={successCloudEbookButtonRef}>o</button>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <img className="align-self-center" src={IconEbook} width="150" height="105" />
                            <p>Your eBook download should begin any moment! Don't wait - start reading to gain insights on Cloud Computing in our rapidly changing digital world. Follow us <a href="https://twitter.com/intraprise" target="_blank" rel="noopener" title="follow us on Twitter">@intraprise</a> for more on Digital Transformation!</p>
                            <p className="small text-muted text-center"><em>If your download does not start immediately, click the Download button</em></p>
                            <a href={EbookDownload} className="btn btn-primary form-control mt-2" download="Cloud Computing EBook.pdf" ref={ebookDownloadButtonRef}><i className="bi bi-cloud-download"></i> Download eBook</a>
                            <button type="button" className="btn btn-secondary form-control mt-2" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </Layout>
    );
}

export default CloudSeries;
