import React, {useEffect, useState} from 'react';
import { Link } from 'gatsby';

const Archivelist = ({props}) => {

  const [archives, setArchives] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [archivesPerPage, setArchivesPerPage] = useState(10);
  let pageNumbers = [];

  useEffect(() => {
      setArchives(props.allPosts);
      setArchivesPerPage(archivesPerPage);
      setStartPage();
  }, []);

  const indexOfLastArchive = currentPage * archivesPerPage;
  const indexOfFirstArchive = indexOfLastArchive - archivesPerPage;
  const currentArchives = archives.slice(indexOfFirstArchive, indexOfLastArchive);

  for (let i=1; i<=Math.ceil(archives.length / archivesPerPage); i++) {
      pageNumbers.push(i);
  }
  const paginate = (event, pageNumber) => {
      event.preventDefault()
      event.stopPropagation()
      setCurrentPage(pageNumber);
  }
  const setStartPage = () => {
    if (props.postId) {
      var archivePages = props.allPosts.reduce((pageArray, item, index) => { 
        const chunkIndex = Math.floor(index/archivesPerPage)

        if(!pageArray[chunkIndex]) {
          pageArray[chunkIndex] = [] // start a new chunk
        }

        pageArray[chunkIndex].push(item)

        return pageArray
      }, [])
      let startPage = 1;
      let iterator = 0;
      archivePages.map((chunk) => {
        let chunkNum = chunk.findIndex(item => parseInt(item.id) === props.postId);
        if (chunkNum > -1) {
          startPage = (iterator + 1);
        }
        iterator++;
      });
      setCurrentPage(startPage);
    }
  }
  

    const renderPostLink = (post) => {
        let link = <></>;
        switch (post.postType) {
          case "curated":
            const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
            const linkUrl = (post.content.match(linkRx)[2]);
            link = <Link href={linkUrl} target="_blank" rel="noopener noreferrer">{post.title}</Link>
            break;
          default:
            {(() => {
              switch (post.id) {
                case props.postId:
                  link = <strong>{post.title}</strong>
                  break;
                default:
                  link = <Link to={`/knowledge-exchange/${post.id}`}>{post.title}</Link>
                  break;
              }
            })()}
            
          }
        return link;
    }

    return (
        <>
            <ol className="list-unstyled mb-0">
                {currentArchives.map(post => (
                  <li className="mb-2" key={post.id}>
                    <span className="small text-muted">{post.date}</span>
                    <br/>{renderPostLink(post)}
                  </li>
                ))}
            </ol>
            <nav>
                <ul className="pagination pagination-sm flex-wrap mt-3">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">page</a>
                  </li>
                    {pageNumbers.map(number => (
                        number == currentPage ?
                            <li key={number} className="page-item active"><a className="page-link">{number}</a></li>
                            :
                            <li key={number} className="page-item">
                                <a onClick={(e) => paginate(e, number)} href="!#" className="page-link">
                                    {number}
                                </a>
                            </li>
                    ))}
                </ul>
            </nav>
        </>

    );
}

export default Archivelist;
