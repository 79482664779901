import React, {useEffect, useRef, useState} from 'react';
import Api from './Api';

const NewsletterSubscribe = () => {
    
    const subscribeModalButtonRef = useRef();
    const closeSubscribeModalButtonRef = useRef();
    const subscribeSuccessModalButtonRef = useRef();

    useEffect(() => {
        
		document.getElementById("but_subscribe_submit").addEventListener('click',      
			(event) => {
				event.preventDefault()
				event.stopPropagation()
				const subscribeForm = event.target.form;
				if (subscribeForm.checkValidity()) {
					submitSubscribe();
				}
				subscribeForm.classList.add('was-validated')
		});

    }, []);


    async function submitSubscribe() {
        const userFname = document.getElementById("txt_subscribe_firstname").value;
        const userLname = document.getElementById("txt_subscribe_lastname").value;
		//const userName = document.getElementById("txt_subscribe_name").value;
		const userEmail = document.getElementById("txt_subscribe_email").value;
		const userCompany = document.getElementById("txt_subscribe_company").value;
		const userIndustry = document.getElementById("txt_subscribe_industry").value;

        const zohoForm = {
            "postUrl": "https://forms.zohopublic.com/dmoore3/form/KnowledgeExchangeRecapSubscribe/formperma/qcDh6ziNh1FDnRioijcs4_e6zHwiunAcmAELnXEb6uM/htmlRecords/submit",
            "formFields": {
                "zf_referrer_name": "",
                "zf_redirect_url": "",
                "zc_gad": "",
                "Name_First": userFname,
                "Name_Last": userLname,
                "Email": userEmail,
				"SingleLine2": userCompany,
				"SingleLine1": userIndustry
            }
        }
        
        // this sends the email via api
        let apiObj = new Api();
        let sendResults = await apiObj.sendZohoForm(zohoForm, "spinner-sendSubscribeMail");

        switch(sendResults.message.toUpperCase()) {
          case "SUCCESS":
            //var contactModal = bootstrap.Modal.getInstance(document.getElementById('modal_contact'));
            //contactModal.hide();
            closeSubscribeModalButtonRef.current.click();
            //var successModal = new bootstrap.Modal(document.getElementById('modal-email-success'), {keyboard: false})
            //successModal.show();
            subscribeSuccessModalButtonRef.current.click();
            break;
          default:
            // @todo - fail code block
        }

		var subscribeForm = document.getElementById('form_subscribe')
		subscribeForm.classList.remove("was-validated");
		subscribeForm.reset();
	}

    
    function showDemoModal() {
        subscribeModalButtonRef.current.click();
    }

    return (
        <>
            <div className="modal fade" id="modal_subscribe" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title text-start">Subscribe to Knowledge Exchange</h5>
                    <button type="button" class="btn invisible" data-bs-toggle="modal" data-bs-target="#modal_subscribe" ref={subscribeModalButtonRef}>launch</button>
                    <div>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeSubscribeModalButtonRef}></button>
                    </div>
                    </div>
                    <div className="modal-body">
                    
                        <form id="form_subscribe" className="needs-validation" novalidate>

                            <div className="row">
                                <div className="col-md-6 form-floating mb-2">
                                    <input type="text" className="form-control" id="txt_subscribe_firstname" title="First Name" placeholder="Enter your first name..." aria-describedby="FirstName" required />
                                    <label for="txt_subscribe_firstname">First Name</label>
                                    <div className="invalid-feedback">
                                        Please provide your first name.
                                    </div>
                                </div>
                                <div className="col-md-6 form-floating mb-2">
                                    <input type="text" className="form-control" id="txt_subscribe_lastname" title="Last Name" placeholder="Enter your last name..." aria-describedby="LastName" required />
                                    <label for="txt_subscribe_lastname">Last Name</label>
                                    <div className="invalid-feedback">
                                        Please provide your last name.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col form-floating">
                                    <input type="email" className="form-control" id="txt_subscribe_email" title="Email" placeholder="Enter your email..." aria-describedby="Email" required />
                                    <label for="txt_subscribe_email">Email address</label>
                                    <div className="invalid-feedback">
                                        Please provide your email.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col form-floating">
                                    <input type="text" className="form-control" id="txt_subscribe_company" title="Company" placeholder="Enter your company..." aria-describedby="Industry" required />
                                    <label for="txt_subscribe_company">Company</label>
                                    <div className="invalid-feedback">
                                        Please provide your company.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col form-floating">
                                    <input type="text" className="form-control" id="txt_subscribe_industry" title="Industry" placeholder="Enter your industry..." aria-describedby="Industry" required />
                                    <label for="txt_subscribe_industry">Industry</label>
                                    <div className="invalid-feedback">
                                        Please provide your industry.
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col">
                                    <button type="submit" className="btn btn-primary form-control" id="but_subscribe_submit">
                                    <i className="spinner-border spinner-border-sm buttonspinner" role="status" id="spinner-sendSubscribeMail">
                                    </i>
                                    Send
                                    </button>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <button type="button" className="btn btn-secondary form-control" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>

                        </form>

                    </div>

                </div>
                </div>
            </div>
                
                
            {/* Email Successfully Sent modal */}
            <div className="modal fade" id="modal_subscribe_success">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Success!</h5>
                            <button type="button" className="btn invisible" data-bs-toggle="modal" data-bs-target="#modal_subscribe_success" ref={subscribeSuccessModalButtonRef}>Launch success modal</button>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <i className="bi bi-check-circle-fill text-primary align-self-center" style={{fontSize:"50px"}}></i>
                            <p className="text-center">Thank you for subscribing to the <em>Knowledge Exchange Recap</em> newsletter!</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default NewsletterSubscribe;